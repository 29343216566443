import React, { useEffect, useState, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import ApiService from "../services/api.service";
import AuthService from "../services/auth.service";
import Table from "./components/table.component";
import { Link } from "react-router-dom";
import moment from "moment";

const Charge = (props) => {
  const [charges, setCharges] = useState([]);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    const fetchData = async () => {
      const data = await ApiService.getCharges(user.token);
      console.log(data.data.data);
      setCharges([...data.data.data]);
    };

    fetchData();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        columns: [
          {
            Header: "Id",
            accessor: "id",
            //id: "id",
            Cell: (row) => <div>{row.original["id"]}</div>,
          },
          {
            Header: "# Orden",
            accessor: "id_purchase",
            //id: "id",
            Cell: (row) => <div>{row.original["id_purchase"]}</div>,
          },
          {
            Header: "Cliente",
            accessor: "Purchase.User.full_name",
          },
          {
            Header: "Monto",
            accessor: "value",
          },
          {
            Header: "Fecha pago",
            accessor: "Purchase.date",
            Cell: (row) => (
              <div>
                {moment(row.original.Purchase.date).format("YYYY-MM-DD")}
              </div>
            ),
          },
          {
            Header: "Ver voucher",
            accessor: "voucher",
            Cell: (row) => (
              <div>
                <Link
                  to={`${process.env.PUBLIC_URL}/order-collections/${row.original.Purchase.id}`}
                  title="Ver pagos"
                >
                  <i
                    className="fa fa-money"
                    style={{
                      width: 35,
                      fontSize: 20,
                      padding: 11,
                      color: "rgb(222 167 11)",
                    }}
                  ></i>
                </Link>
              </div>
            ),
          },
        ],
      },
    ],
    []
  );

  return (
    <Fragment>
      <Breadcrumb title={`Registro de Pagos cliente`} parent="Digital" />
      {/* <!-- Container-fluid starts--> */}
      <div className="container-fluid">
        <Table data={charges} columns={columns} />
      </div>
    </Fragment>
  );
};

export default Charge;
