import React, { Component, Fragment } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
// import TextArea from 'react-validation/build/textarea';
import CheckButton from 'react-validation/build/button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
// import { Link } from 'react-router-dom';
// import Swal from 'sweetalert2';
import { withRouter } from 'react-router-dom';


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Este campo es obligatorio!
      </div>
    );
  }
};

let ambassador_users = [];
let itemsAllUsers = [];

class Tabset_user extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeAlias = this.onChangeAlias.bind(this);
    this.onChangeCellPhone = this.onChangeCellPhone.bind(this);
    this.onChangeDateBirthday = this.onChangeDateBirthday.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onchangeNroDoc = this.onchangeNroDoc.bind(this);
    this.onChangeTopClient = this.onChangeTopClient.bind(this);

    this.state = {
      message: '',
      name: '',
      alias: '',
      last_name: '',
      email: '',
      cell_phonenumber: '',
      password: '',
      confirm_password: '',
      errors: {},
      status: 'ACTIVADO',
      top_client:'NO',
      date_birthday:'',
      nro_doc: '',
      selectedOptionReferidoGeneral: null,
      selectedOption: null,
      selectedRoleOption: null,
      selectedTipoDocOption: null,
      dataUsers: [],
      dataUsersAll: [],
      dataRoles: [
        { value: 'ADMIN', label: 'Administrador' },
        { value: 'REFERIDO', label: 'Referido' },
        { value: 'EMBAJADOR', label: 'Embajador' },
        { value: 'CLIENTE', label: 'Cliente' },
      ],
      dataTipoDocumento: [
        { value: 'DNI', label: 'DNI' },
        { value: 'PASAPORTE', label: 'Pasaporte' },
      ],
    };
  }

  componentWillMount() {
    this.getDataUsers();
  }

  onChangeStatus(e) {
    this.setState({
      status: e.target.value,
    });
  }

  onChangeTopClient(e) {
    this.setState({
      top_client: e.target.value,
    });
  }
  onChangeDateBirthday(e) {
    this.setState({
      date_birthday: e.target.value,
    });
  }
  

  onchangeNroDoc(e) {
    this.setState({
      nro_doc: e.target.value,
    });
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value,
    });
  }

  onChangeLastName(e) {
    this.setState({
      last_name: e.target.value,
    });
  }

  onChangeAlias(e) {
    this.setState({
      alias: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangeCellPhone(e) {
    this.setState({
      cell_phonenumber: e.target.value,
    });
  }


  onChangePassword = (e) => {
    this.setState({ password: e.target.value }, this.validatePasswords);
  };
  
  onChangeConfirmPassword = (e) => {
    this.setState({ confirm_password: e.target.value }, this.validatePasswords);
  };

  validatePasswords = () => {
    const { password, confirm_password } = this.state;
    if (password !== confirm_password) {
      this.setState({
        errors: { confirm_password: 'La contraseña no coincide.' },
      });
    } else {
      this.setState({
        errors: { confirm_password: '' },
      });
    }
  };

  getDataUsers() {
    var user = AuthService.getCurrentUser();
    ApiService.getUsers(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          this.items = res.data.map(function (row) {
            return {
              value: row.id,
              label: row.name + ' ' + row.last_name,
              key: row.id,
              role: row.role,
              status_confirm: row.status_confirm,
              status: row.status,
            };
          });

          let itemsUser = [];
          this.items.map((row, index) => {
            if (
              row.role == 'EMBAJADOR' &&
              row.status_confirm == 'APROBADO' &&
              row.status == 'ACTIVADO'
            ) {
              itemsUser.push(row);
              ambassador_users.push(row);
            }
          });

          this.setState({ dataUsers: itemsUser });

          //Mostrar todos los usuarios
          let itemsAllUsers = [];
          this.items.map((row, index) => {
            if (
              row.status_confirm == 'APROBADO' &&
              row.status == 'ACTIVADO'
            ) {
              itemsAllUsers.push(row);
            }
          });

          this.setState({ dataUsersAll: itemsAllUsers });
          if (this.props.action == 'update') {
            let id_user = this.props.id_user;
            this.getUser(id_user);
          }
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getUser(id_user) {
    var user = AuthService.getCurrentUser();
    ApiService.getUserDetails(user.token, id_user).then(
      (res) => {
        //console.log(res)
        if (res && res.status === 'success') {
          this.setState({ name: res.data.name ? res.data.name : '' });
          this.setState({
            last_name: res.data.last_name ? res.data.last_name : '',
          });
          this.setState({ email: res.data.email ? res.data.email : '' });
          this.setState({
            nro_doc: res.data.nro_document ? res.data.nro_document : '',
          });
          this.setState({
            cell_phonenumber: res.data.Profile.cell_phonenumber
              ? res.data.Profile.cell_phonenumber
              : '',
          });
          
          this.setState({
            top_client: res.data && res.data.top_client ? res.data.top_client : '',
          });
          
          this.setState({
            date_birthday: res.data && res.data.date_birthday ? res.data.date_birthday : '',
          });
          
          this.setState({ status: res.data.status });
          this.setState({ alias: res.data.alias });
          
          for (let i = 0; i < this.state.dataUsersAll.length; i++) {
            if (this.state.dataUsersAll[i]['value'] === res.data.id_mainreferer) {
              this.handleChangeReferidoGeneral(this.state.dataUsersAll[i]);
            }
          }
          for (let i = 0; i < this.state.dataUsers.length; i++) {
            if (this.state.dataUsers[i]['value'] === res.data.id_ambassador) {
              this.handleChange(this.state.dataUsers[i]);
            }
          }
          for (let i = 0; i < this.state.dataRoles.length; i++) {
            if (this.state.dataRoles[i]['value'] === res.data.role) {
              this.handleRoleChange(this.state.dataRoles[i]);
            }
          }
          for (let i = 0; i < this.state.dataTipoDocumento.length; i++) {
            if (
              this.state.dataTipoDocumento[i]['value'] ===
              res.data.type_document
            ) {
              this.handleTipoDocChange(this.state.dataTipoDocumento[i]);
            }
          }
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  handleRegister(e) {
    this.setState({
      message: '',
      loading: true,
    });
  
    this.form.validateAll(); 
  
    let errors = {}; // Objeto para almacenar errores
  
    // Validar contraseña y confirmación
    if (this.state.password !== this.state.confirm_password) {
      errors.confirm_password = 'La contraseña no coincide.';
    }
  
    // Validar rol seleccionado
    if (!this.state.selectedRoleOption) {
      errors.role = 'Debes seleccionar un rol.';
    }
  
    // Validar tipo de documento
    if (!this.state.selectedTipoDocOption) {
      errors.tipo_doc = 'Debes seleccionar un tipo de documento.';
    }
  
    // Validar número de documento
    if (!this.state.nro_doc) {
      errors.nro_doc = 'El número de documento es obligatorio.';
    }
  
    // Validar correo electrónico
    if (!this.state.email || !/\S+@\S+\.\S+/.test(this.state.email)) {
      errors.email = 'Ingrese un correo electrónico válido.';
    }
  
    // Validar nombre y apellido
    if (!this.state.name) {
      errors.name = 'El nombre es obligatorio.';
    }
    if (!this.state.last_name) {
      errors.last_name = 'El apellido es obligatorio.';
    }
  
    // Validar número de celular
    if (!this.state.cell_phonenumber || !/^\d{9}$/.test(this.state.cell_phonenumber)) {
      errors.cell_phonenumber = 'Ingrese un número de celular válido (9 dígitos).';
    }
  
    // Actualizar estado de errores
    this.setState({ errors });
  
    // Verificar si hay errores antes de continuar
    if (Object.keys(errors).length > 0) {
      this.setState({ loading: false });
      return;
    }
  
    // Obtener usuario autenticado
    let user = AuthService.getCurrentUser();
    let data_user = {
      name: this.state.name,
      last_name: this.state.last_name,
      email: this.state.email,
      cell_phonenumber: this.state.cell_phonenumber,
      password: this.state.password,
      id_mainreferer: this.state.selectedOptionReferidoGeneral && this.state.selectedOptionReferidoGeneral.value
        ? this.state.selectedOptionReferidoGeneral.value
        : 0,
      id_ambassador: this.state.selectedOption && this.state.selectedOption.value
        ? this.state.selectedOption.value
        : 0,
      role: this.state.selectedRoleOption.value,
      type_document: this.state.selectedTipoDocOption.value,
      nro_document: this.state.nro_doc,
      status: this.state.status,
    };
  
    // Agregar valores opcionales solo si existen
    if (this.state.alias) {
      data_user.alias = this.state.alias;
    }
    if (this.state.date_birthday) {
      data_user.date_birthday = this.state.date_birthday;
    }

    if (this.state.top_client) {
      data_user.top_client = this.state.top_client;
    }
  
    // Enviar datos al servicio
    ApiService.postUser(user.token, data_user)
      .then((res) => {
        if (res && res.status === 'success') {
          this.props.history.push('/list-user');
          toast.success('Usuario creado!');
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        this.setState({
          loading: false,
          message: resMessage,
        });
      });
  }
  

  handleUpdate(e) {
    this.setState({
      message: '',
      loading: true,
    });

    this.form.validateAll();

    if (this.state.password) {
        if (this.state.password !== this.state.confirm_password) {
            this.setState({
                errors: { confirm_password: 'La contraseña no coincide.' },
            });
        } else {
            this.setState({
                errors: { confirm_password: '' },
            });
        }
    }

    if (!this.state.selectedRoleOption) {
        this.setState({
            errors: { role: 'Error' },
        });
    } else {
        this.setState({
            errors: { role: '' },
        });
    }

    if (!this.state.selectedTipoDocOption) {
        this.setState({
            errors: { tipo_doc: 'Error' },
        });
    } else {
        this.setState({
            errors: { tipo_doc: '' },
        });
    }

    if (
        this.checkBtn.context._errors.length === 0 &&
        this.state.password === this.state.confirm_password &&
        !this.state.errors.role
    ) {
        var user = AuthService.getCurrentUser();
        let data_user = {};

        data_user['id_user'] = this.props.id_user;
        data_user['name'] = this.state.name;
        data_user['last_name'] = this.state.last_name;

        if (this.state.email && this.state.email !== '') {
            data_user['email'] = this.state.email;
        }

        data_user['cell_phonenumber'] = this.state.cell_phonenumber;

        if (this.state.password) {
            data_user['password'] = this.state.password;
        }

        data_user['id_mainreferer'] =
            this.state.selectedOptionReferidoGeneral &&
            this.state.selectedOptionReferidoGeneral.value
                ? this.state.selectedOptionReferidoGeneral.value
                : 0;

        data_user['id_ambassador'] =
            this.state.selectedOption && this.state.selectedOption.value
                ? this.state.selectedOption.value
                : 0;

        data_user['role'] = this.state.selectedRoleOption
            ? this.state.selectedRoleOption.value
            : '';

        data_user['type_document'] = this.state.selectedTipoDocOption
            ? this.state.selectedTipoDocOption.value
            : '';

        data_user['nro_document'] = this.state.nro_doc;
        data_user['status'] = this.state.status;

        data_user['date_birthday'] = this.state.date_birthday
            ? this.state.date_birthday
            : '';

        data_user['top_client'] = this.state.top_client
        ? this.state.top_client
        : null;

        

        if (this.state.alias && this.state.alias !== '') {
            data_user['alias'] = this.state.alias;
        }

        ApiService.putUser(user.token, data_user).then(
            (res) => {
                if (res) {
                    if (res && res.status == 'success') {
                        toast.success('Usuario actualizado!');
                        this.props.history.push('/list-user');
                    } else {
                        toast.error(res.message);
                    }
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                this.setState({
                    loading: false,
                    message: resMessage,
                });
            }
        );
    } else {
        const errors = {};

        if (this.state.password !== this.state.confirm_password) {
            errors.confirm_password = 'Las contraseñas no coinciden.';
        }

        if (!this.state.selectedRoleOption || !this.state.selectedRoleOption.value) {
            errors.role = 'El rol es obligatorio.';
        }

        this.setState({ errors, loading: false }, () => {
            console.log('Errores de validación:', this.state.errors);
        });
    }
}


  handleChangeReferidoGeneral = (selectedOptionReferidoGeneral) => {
    this.setState({ selectedOptionReferidoGeneral }, () =>
      console.log(`Option selected:`, this.state.selectedOptionReferidoGeneral)
    );
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  handleRoleChange = (selectedRoleOption) => {
    if (
      selectedRoleOption.value === 'REFERIDO' ||
      selectedRoleOption.value === 'ADMIN' ||
      selectedRoleOption.value === 'EMBAJADOR' ||
      selectedRoleOption.value === 'CLIENTE'
    ) {
      document.getElementById('row_referidogeneral').style.display = 'flex';
      document.getElementById('row_embajador').style.display = 'flex';
    } else {
      document.getElementById('row_referidogeneral').style.display = 'none';
      document.getElementById('row_embajador').style.display = 'none';
      
      this.setState({
        selectedOptionReferidoGeneral: null,
      });

      this.setState({
        selectedOption: null,
      });

      this.setState({
        dataUsers: [],
      });

      this.setState({
        dataUsersAll: [],
      });

      this.setState({
        dataUsers: ambassador_users,
      });

      this.setState({
        dataUsersAll: itemsAllUsers,
      });
    }

    this.setState({ selectedRoleOption }, () =>
      console.log(`Option selected:`, this.state.selectedRoleOption)
    );
  };

  handleTipoDocChange = (selectedTipoDocOption) => {
    this.setState({ selectedTipoDocOption }, () =>
      console.log(`Option selected:`, this.state.selectedTipoDocOption)
    );
  };

  formPreventDefault(e) {
    e.preventDefault();
  }

  render() {
    const { selectedOptionReferidoGeneral } = this.state;
    const { selectedOption } = this.state;
    const { selectedRoleOption } = this.state;
    const { selectedTipoDocOption } = this.state;
    return (
      <Fragment>
        <Tabs>
          <TabList className="nav nav-tabs tab-coupon">
            <Tab className="nav-link">Cuenta</Tab>
            
          </TabList>
          <TabPanel>
            <Form
              
              autoComplete="off"
              ref={(c) => {
                this.form = c;
              }}
              onSubmit={this.formPreventDefault}
            >
              <h4>Datos de la Cuenta</h4>
              <div>
                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Nombres
                  </label>
                  <Input
                    name="name"
                    value={this.state.name}
                    type="text"
                    className="form-control"
                    style={{ maxWidth: '100%' , margin:'5px' }}
                    onChange={this.onChangeName}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Apellidos
                  </label>
                  <Input
                    name="last_name"
                    value={this.state.last_name}
                    type="text"
                    className="form-control"
                    style={{ maxWidth: '100%' , margin:'5px' }}
                    onChange={this.onChangeLastName}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Seudónimo
                  </label>
                  <Input
                    name="last_name"
                    value={this.state.alias}
                    type="text"
                    className="form-control"
                    style={{ maxWidth: '100%' , margin:'5px'}}
                    onChange={this.onChangeAlias}
                  />
                </div>
                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Tipo de documento
                  </label>
                  <div style={{ width: '56%', margin:'5px'}}>
                    <Select
                      value={selectedTipoDocOption}
                      onChange={this.handleTipoDocChange}
                      options={this.state.dataTipoDocumento}
                    />
                    {this.state.errors.tipo_doc &&
                    !this.state.selectedTipoDocOption ? (
                      <div className="alert alert-danger" role="alert">
                        Este campo es obligatorio!
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> # Nro. Documento
                  </label>
                  <Input
                    name="name"
                    value={this.state.nro_doc}
                    type="text"
                    className="form-control"
                    style={{ maxWidth: '100%' , margin:'5px' }}
                    onChange={this.onchangeNroDoc}
                    validations={[required]}
                    maxLength="11"
                  />
                </div>

                <div className="form-group">
  <label className="col-xl-3 col-md-4">
    <span>*</span> ¿Es cliente TOP?
  </label>
  <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
  <label className="d-block" style={{ marginRight: '1rem', marginLeft: '2rem' }}>
      <Input
        id="edo-ani"
        name="top_client"
        value="si"
        type="radio"
        className="radio_animated"
        checked={this.state.top_client === 'si'}
        onChange={this.onChangeTopClient}
      />
      Sí
    </label>
    <label className="d-block">
      <Input
        id="edo-ani"
        name="top_client"
        value="no"
        type="radio"
        className="radio_animated"
        checked={this.state.top_client === 'no'}
        onChange={this.onChangeTopClient}
      />
      No
    </label>
  </div>
</div>

                  <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> # Fecha de Nacimiento
                  </label>
                  <Input
                    name="name"
                    value={this.state.date_birthday }
                    type="date"
                    className="form-control"
                    style={{maxWidth: '100%' , margin:'5px' }}
                    onChange={this.onChangeDateBirthday }
                  />
                </div>

                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> # Celular
                  </label>
                  <Input
                    name="name"
                    value={this.state.cell_phonenumber}
                    type="text"
                    className="form-control"
                    style={{maxWidth: '100%' , margin:'5px' }}
                    onChange={this.onChangeCellPhone}
                    validations={[required]}
                    maxLength="9"
                  />
                </div>
                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Email
                  </label>
                  <Input
                    className="form-control"
                    style={{ maxWidth: '100%' , margin:'5px' }}
                    name="name"
                    value={this.state.email}
                    type="text"
                    onChange={this.onChangeEmail}
                  />
                </div>
                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Estado
                  </label>
                  <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                  <label className="d-block" style={{ marginRight: '1rem', marginLeft: '2rem' }}>
                      <Input
                        id="edo-ani"
                        name="status"
                        value="ACTIVATED"
                        type="radio"
                        className="radio_animated"
                        checked={this.state.status === 'ACTIVADO'}
                        onChange={this.onChangeStatus}
                        validations={[required]}
                      />
                      Activo
                      {/*<input className="radio_animated" id="edo-ani" type="radio" name="rdo-ani" />*/}
                    </label>
                    <label className="d-block">
                      <Input
                        id="edo-ani"
                        name="status"
                        value="DESACTIVATED"
                        type="radio"
                        className="radio_animated"
                        checked={this.state.status === 'DESACTIVADO'}
                        onChange={this.onChangeStatus}
                        validations={[required]}
                      />
                      Inactivo
                      {/*<input className="radio_animated" id="edo-ani1" type="radio" name="rdo-ani" />*/}
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Rol
                  </label>
                  <div style={{ width: '56%' }}>
                    <Select
                      value={selectedRoleOption}
                      onChange={this.handleRoleChange}
                      options={this.state.dataRoles}
                    />
                    {this.state.errors.role &&
                    !this.state.selectedRoleOption ? (
                      <div className="alert alert-danger" role="alert">
                        Este campo es obligatorio!
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div
                  id="row_referidogeneral"
                  className="form-group"
                  style={{ display: 'none' }} >
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Referido general
                  </label>
                  <div style={{ width: '56%' }}>
                    <Select
                      value={selectedOptionReferidoGeneral}
                      onChange={this.handleChangeReferidoGeneral}
                      options={this.state.dataUsersAll}
                    />
                  </div>
                </div>
                <div
                  id="row_embajador"
                  className="form-group"
                  style={{ display: 'none' }}
                >
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Embajador
                  </label>
                  <div style={{ width: '56%' }}>
                    <Select
                      value={selectedOption}
                      onChange={this.handleChange}
                      options={this.state.dataUsers}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Contraseña
                  </label>
                  <Input
                    className="form-control"
                    style={{maxWidth: '100%' , margin:'5px' }}
                    name="password"
                    value={this.state.password}
                    type="password"
                    onChange={this.onChangePassword}
                    validations={
                      this.props.action == 'create' ? [required] : []
                    }
                    autoComplete="new-password"
                  />
                </div>
                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Confirmar Contraseña
                  </label>
                  <Input
                    className="form-control"
                    style={{ maxWidth: '100%' , margin:'5px'}}
                    name="name"
                    value={this.state.confirm_password}
                    type="password"
                    onChange={this.onChangeConfirmPassword}
                    validations={
                      this.props.action === 'create' ? [required] : []
                    }
                  />
                  {this.state.errors.confirm_password && (
                    <div className="alert alert-danger" role="alert" style={{ marginLeft: '5px' }}>
                      {this.state.errors.confirm_password}
                    </div>
                  )}
                </div>
              </div>

              <button
                onClick={() =>
                  this.props.action == 'create'
                    ? this.handleRegister()
                    : this.handleUpdate()
                }
                className="btn btn-primary"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Guardar</span>
              </button>
              

              {this.state.message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: 'none' }}
                ref={(c) => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </TabPanel>
          
        </Tabs>
        
      </Fragment>
    );
  }
}

//export default Tabset_user;
export default withRouter(Tabset_user);