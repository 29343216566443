import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable'
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";

export class Abandoned_cart extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            dataCateg: [],
            results: [],
            filter: '',
            temp: [],
        };
    }

    componentWillMount() {
        this.getDataUser();
    }

    getDataUser(){
        var user = AuthService.getCurrentUser();
        ApiService.getAbandonedCart(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    const items = res.data.map(function(row) {
                        return { 
                            Fecha: row.date,
                            Total : row.total_orders, 
                            Nombres: row.User ? `${row.User.name} ${row.User.last_name}` : 'Usuario no disponible',
                            'última conexión': row.last_connection
                        }
                    })
                this.setState({ results:items });
                this.setState({ temp:items });
                this.renderResults(this.state.results.length);
                }
            },
            error => {
                const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            }
            );
    }

    renderResults(length){
        return this.state.results.length === 0 
            ? 'No hay datos.'
            : <Datatable
            multiSelectOption={false}
            myData={this.state.results}
            pageSize={length<=100?length:100}
            pagination={true}
            class="-striped -highlight"
        />
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Carritos Abandonados" parent="Users" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h5>Carritos Abandonados</h5>
                        </div>
                        <div className="card-body">
                            
                            <div className="clearfix"></div>
                            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete text-left">
                                
                                {this.renderResults()}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Abandoned_cart;
